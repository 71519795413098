import { useState } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from '@sentry/react'
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Montserrat, Oswald } from 'next/font/google'
import Head from 'next/head'
import { useRouter } from 'next/router'

import ErrorBoundaryFallback from '~/components/elements/errors/ErrorBoundaryFallback'
import { createQueryClient } from '~/lib/createQueryClient'
import { AppContextProvider } from '~/lib/state_management/context/appContext'
import '~/lib/utils/i18n'

import '../styles/globals.css'

const montserrat = Montserrat({
  weight: ['300', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
})

const oswald = Oswald({
  display: 'swap',
  subsets: ['latin'],
})

const DynamicAnalyticsWithNoSSR = dynamic(() => import('../lib/utils/analytics/analytics'), {
  ssr: false,
})

const host = process.env.NEXT_PUBLIC_HOST

function MyApp({ Component, pageProps }: AppProps) {
  // With SSR always create the QueryClient inside the app
  // https://tanstack.com/query/v5/docs/framework/react/guides/ssr
  const [queryClient] = useState(createQueryClient)

  const router = useRouter()
  const ogPath = router.asPath.split('?')[0]

  const description =
    "Online classes for the modern parent — taught by leading experts.  Learn breastfeeding, infant through child CPR, sleep training, methods for soothing a fussy baby, newborn care, and more. Kids don't come with a manual, but this is pretty close."

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppContextProvider>
              <style jsx global>{`
                :root {
                  --montserrat: ${montserrat.style.fontFamily};
                  --oswald: ${oswald.style.fontFamily};
                }
              `}</style>

              <Head>
                <title>Tinyhood</title>
                <meta name="description" key="description" content={description} />
                <meta
                  name="keywords"
                  content="Tinyhood, Tinyhood, Inc., Social Networking, Lifestyle, ios apps, Mom, Parent, mom group, pregnancy app, mom app, baby, tiny hood, Parenting advice, parenting expert, advice, sleep training, breastfeeding, nutrition, potty training, behavior issues"
                />

                <meta property="og:site_name" content="Tinyhood" />
                <meta property="og:title" key="og_title" content="Tinyhood" />
                <meta property="og:url" content={`${host}${ogPath}`} />
                <meta property="og:image" content={`${host}/api/og`} />
                <meta
                  property="og:image:alt"
                  content="Two parents watching a video on a tablet. The name Tinyhood is shown in the bottom right corner."
                />
                <meta property="og:type" content="website" />
                <meta property="og:description" key="og_description" content={description} />

                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, viewport-fit=cover, user-scalable=no"
                ></meta>
                <meta name="p:domain_verify" content="1ad20ed82d7624c00eaadf6ba803e85b" />
                <link rel="image_src" href="/images/icons/tinyhood-logo-180.png" />
                <link rel="icon" href="/favicon.ico" sizes="32x32" />
                <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
                <link rel="apple-touch-icon" href="/images/icons/tinyhood-logo-180.png" />
                <link rel="manifest" href="/site.webmanifest" crossOrigin="use-credentials" />
              </Head>
              <DynamicAnalyticsWithNoSSR />
              <Component {...pageProps} />
            </AppContextProvider>
          </LocalizationProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}

export default MyApp
